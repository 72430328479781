<template>
    <div class="payQrcode">
        <Header backType="blank" :back="!hiddenBackButton" :backEvent="true" @backEvent="closeQrCode" />
        <div class="payQrcodeContainer">
            <div class="publicHead">
                <p>商户扫我付款码</p>
                <div class="subTitle">MY PAYMENT CODE</div>
            </div>
            <div>
                <div class="codeCard">
                    <div class="codeCardTitle">支付环境安全！请放心支付</div>
                    <img :src="payData.qrCode" />
                    <div class="codeCardTitle2" v-if="!hiddenBackButton && resInfon">
                        {{resInfon}}
                    </div>
                </div>
                <div class="falseTxt">如付款失败，请联系<span @click="ihtml.telCall('01056304858')" class="phoneNumber">Call Center</span></div>
            </div>
        </div>
        <MessageBox ref="mesBox" type="tips" :messageTxt="msg" @onOk="closeQrCode(true)"></MessageBox>
        <MessageBox ref="mesBox1" type="tips" messageTxt="返回后排队失效,是否确定返回?" @onOk="destoryVisitor"></MessageBox>
    </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { miceService } from "@/service/miceService.js";
import iSignalr from "@/utils/smart-signalr";
import ding from '@/utils/audio/ding.wav'
import {
	SmartStorage
} from 'smart-core-util'
export default {
    data() {
        return {
            hiddenBackButton: false,
            audio: new Audio(ding),
            payHub: null,
            resInfon: "",
            payData: {
                expenseId: null,
                payOption: "支付宝",
                payChannel: "1",
                paySelect: "商户扫我",
                qrCode: ""
            },
            msg: ''
        };
    },
    created() {
        this.openSignal();
    },
    computed: {
        ...mapGetters(["expenseId",'event'])
    },
    methods: {
        ...mapMutations(["FIN3_RESULT"]),
        openSignal() {
            var _this = this;
            this.payHub = new iSignalr({
                hubName: "payHub",
                queryParams: "group=" + this.expenseId,
                listeners: [
                    {
                        methodName: "enter",
                        method: function(data) {
                            console.log("enter", data);
                            _this.on_Enter(data);
                        }
                    },
                    {
                        methodName: "refresh",
                        method: function(data) {
                            console.log("refresh", data);
                            _this.on_refresh(data);
                        }
                    },
                    {
                        methodName: "rejected",
                        method: function(data) {
                            console.log("rejected", data);
                            _this.on_rejected(data);
                        }
                    },
                    {
                        methodName: "completed",
                        method: function(data) {
                            console.log("completed", data);
                            _this.on_completed(data);
                        }
                    }
                ],
                host: SmartStorage.get("Uris").Uris.signalr
            });
            this.payHub.install().then(() => {
                this.RequestPayQr();
            });
        },
        on_Enter: function(data) {
            console.log("--on_Enter-begin--");
            console.log(data);
            console.log("--on_Enter-end--");
            if (data && data.isQueueUp == true && data.message) {
                console.log(data);
                this.resInfon = data.message;
                this.hiddenBackButton = false;
            }
        },
        on_refresh: function(data) {
            if (data.fileKey) {
                this.hiddenBackButton = true;
                this.payData.qrCode = data.fileKey;
                // alert(this.payData.qrCode)
                this.payData = Object.assign({}, this.payData);
            }
        },
        on_rejected: function(data) {
            this["FIN3_RESULT"](null);
            var state = this.payHub.connection.state;
            console.log(state);
            this.closeInstallHub();
            if (state != 4) {
                this.msg = data.message
                this.$refs.mesBox.openPop();
            }
        },
        on_completed: function(data) {
            this.openInvoice(data);
        },
        RequestPayQr: async function() {
            this.payData.expenseId = this.expenseId;
            miceService.requestPayQr(this.payData).then(res => {
                if (res && res.result && res.result.status) {
                    this.resInfon = res.result.msg;
                    this.hiddenBackButton = !res.result.data;
                }
            })
        },
        closeQrCode: function(isToList) {
            if (isToList == true) {
                this.$router.push({ path: "/orderList" });
            } else {
                this.$refs.mesBox1.openPop();
            }
        },
        destoryVisitor: function() {
            this.$router.push({ path: "/paySelect" });
            this.payHub.invoke({
                methodName: "destoryVisitor",
                args: { expenseId: this.expenseId }
            });
            this.closeInstallHub();
        },
        getQrCode() {
            miceService.getQrcode(this.payData).then(res => {
                if(res && res.success) {
                    console.log('成功')
                }
            })
        },
        openInvoice(data) {
            this.audio.play();
            this["FIN3_RESULT"](null);
            this["FIN3_RESULT"](data);
            this.closeInstallHub();
            setTimeout(() => {
                if (data.message) {
                    data.message = null;
                }
                this["FIN3_RESULT"](data);
            }, 3000);
            this.$router.push({ path: "/uploadInvoice" });
        },
        closeInstallHub() {
            if (this.payHub) {
                this.payHub.uninstall();
            }
        }
    }
};
</script>
<style lang="scss">

.payQrcode {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--themeColor);

    .payQrcodeContainer {
        position: absolute;
        top: 0.4rem;
        right: 0;
        left: 0;
        bottom: 0;
        overflow: scroll;
        padding-bottom: 0.5rem;

        .publicHead {
            color: #ffffff !important;
        }
        .subTitle {
            font-size: 0.1rem;
            text-align: left;
            margin-left: 0.25rem;
            margin-top: 0.06rem;
        }
        .codeCard {
            margin: 0 0.45rem;
            border-radius: 0.13rem;
            background: #ffffff;
            padding: 0.3rem 0 0.5rem;

            .codeCardTitle {
                background: #fafafa !important;
                height: 0.48rem;
                line-height: 0.48rem;
                font-size: 0.12rem;
                color:  var(--themeColor);
                margin-bottom: 0.3rem;
            }

            .codeCardTitle2 {
                background: #fafafa !important;
                height: 0.48rem;
                line-height: 0.48rem;
                font-size: 0.12rem;
                color:  var(--themeColor);
                margin-top: 0.3rem;
            }

            img {
                width: 80%;
                height: 2.28rem;
            }
        }
        .falseTxt {
            margin-top: 0.18rem;
            color: #ffffff;
            font-size: 0.12rem;
        }
    }
}
</style>
